import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../../components/layouts/layout"
import HeadMeta from "../../components/layouts/headmeta"
import ProgressBar from "../../components/layouts/progressbar"
import Faq from "../../components/faq"
import { changeProgressBarOnScroll } from "../../components/utils/index"
import HowItWorks from "../../components/how-it-works"
import CTA from "../../components/cta"

export default class DNAExplorerCarrierPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Sales", "DNA Explorer Carrier")
  }

  render() {
    const { sections } = pageData
    return (
      <Layout>
        <div className="product-page" id="dna-explorer">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="hero hero-landing bg-gray"
            id="dna-explorer-carrier"
            style={{
              maxHeight: "90vh",
              backgroundPosition: "center",
            }}
          >
            <div className="background-tint tint-light d-flex align-items-center">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-lg-6 offset-lg-6 text-center text-md-right order-2 order-lg-1 p-4">
                    <img
                      className="img-fluid"
                      src={withPrefix("img/logo/DNA-Explorer-Carrier.png")}
                      style={{ maxWidth: "200px" }}
                      alt="DNA Explorer Carrier"
                    />
                    <h1>Get Tested For Your Future Family</h1>
                    <p>
                      Discover your carrier status to prevent
                      <br /> your future family from potentially
                      <br /> inheriting genetic disorders.
                    </p>
                    <a
                      className="btn btn-explorer-carrier-purple"
                      href="https://app.advanxhealth.com/products/overview?product=35"
                    >
                      Get It Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="about" className="about">
            <div className="container">
              <div className="text-center">
                <h2>
                  Ease Your Worries Before Planning a Pregnancy With{" "}
                  <img
                    className="img-fluid"
                    src={withPrefix("img/logo/DNA-Explorer-Carrier.png")}
                    style={{ maxWidth: "250px" }}
                    alt="DNA Explorer Carrier"
                  />
                </h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img
                    src="/img/product/newbox.png"
                    className="img-fluid"
                    alt="Product box"
                  />
                </div>
                <div className="col-md-8">
                  <p className="lead" style={{ fontSize: "1.4rem" }}>
                    Your DNA + Your Partner’s DNA = Your Baby’s DNA
                  </p>

                  <p className="lead mt-3">
                    Blood tests and health checkups are both tools that allow us
                    to be aware of our current health condition. However, as a
                    potential carrier, you will not display any symptoms that
                    come with the particular disease. This is why it is
                    important to test for genetic mutations before making the
                    big decision to conceive.
                    <br />
                    <br />
                    Our DNA Explorer Carrier package provides just that! The
                    package includes a comprehensive description of your
                    potential carrier genes and individual comments from a
                    genetic counsellor.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="did-you-know pt-4">
            <div className="container">
              <h2 className="text-center">Did You Know?</h2>
              <div className="row my-5 align-items-center">
                {sections.map(s => {
                  return (
                    <div className="item-holder col-sm-4">
                      <div className="card">
                        <img
                          className="card-img-top"
                          style={{ maxHeight: "250px" }}
                          src={withPrefix(s.img)}
                          alt="pictures"
                        />{" "}
                        <div className="card-body text-center">
                          <h5 className="card-title">{s.name}</h5>
                          <p className="card-text">{s.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          <section id="extra-features" className="extra-features bg-white pt-0">
            <div className="container text-center">
              <header>
                <div className="my-4">
                  <h2 className="text-center">
                    What's Included In{" "}
                    <img
                      className="img-fluid"
                      src={withPrefix("img/logo/DNA-Explorer-Carrier.png")}
                      style={{ maxWidth: "250px" }}
                      alt="DNA Explorer Carrier"
                    />
                  </h2>
                  <div className="row align-items-center m-0">
                    <div className="col-md-5 offset-md-1 card border-explorer-carrier-purple p-2">
                      <h5 className="text-center">
                        Genetic mutation screening for 13 recessive genetic
                        disorders
                      </h5>
                    </div>
                    <div className="col-md-1">
                      <h2 className="text-explorer-carrier-purple">
                        <i className="fa fa-plus"></i>
                      </h2>
                    </div>
                    <div className="col-md-5 card border-explorer-carrier-purple p-2">
                      <h5 className="text-center">
                        Individual Comments from Professional Genetic Counsellor
                      </h5>
                    </div>
                    <div className="col-md-5 offset-md-7 text-center">
                      <small style={{ fontSize: "0.7rem" }}>
                        *Consultation will be separately charged at RM 200 and
                        paid directly to our partner Genetic Counsellor, Ms
                        Juliana Lee (President of Genetic Counselling Asia (GCA)
                      </small>
                    </div>
                  </div>
                </div>
              </header>
              <hr />
              <div className="row align-items-center my-4">
                <div className="col-md-6 text-left">
                  <h2>Carrier Tests</h2>
                  <p>
                    13 reports on recessive genetic disorders based on your DNA
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Biotinidase deficiency</li>
                        <li>G-6-PD</li>
                        <li>Gaucher disease</li>
                        <li>Beta thalassemia</li>
                        <li>Niemann-Pick's disease</li>
                        <li>Maple syrup urine disease</li>
                        <li>Phenylketonuria</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Wilson's disease degeneration</li>
                        <li>Sickle cell anemia</li>
                        <li>Tay-Sachs disease</li>
                        <li>Spinal muscular atrophy</li>
                        <li>Severe combined immunodeficiency disease</li>
                        <li>X-linked severe combined immunodeficiency</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    src={withPrefix("/img/illustrations/carrier-reports.svg")}
                    className="img-fluid p-5"
                    alt="Carrier Test"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="how-it-works  pt-4">
            <HowItWorks />
          </section>
          <section className="why-important container pt-4">
            <div className="row">
              <div className="col-12 text-left">
                <h2 className="text-center mb-5">
                  Why It Is Important To See A Genetic Counsellor
                </h2>
                <p>
                  Results from the carrier test can be quite difficult to
                  comprehend. Although there are many online services that offer
                  tutorials, they cannot rival the immense knowledge that a
                  genetic counsellor will possess. Genetic counsellors are
                  experienced in the field, they interpret your results and give
                  you advice accordingly. They are able to answer your
                  genetic-related questions, from the most basic ones, such as
                  “What are mutations?” to the more robust ones.
                </p>
                <p>
                  If you receive a positive result, they can offer you a
                  sympathetic ear and assist you in managing your emotions. When
                  there is a need for healthcare providers or even support
                  groups, they will point you in the right direction.
                </p>
              </div>
            </div>
          </section>
          <section className="p-0 p-md-5 mb-md-5">
            <div
              className="container bg-explorer-carrier-purple py-5 my-0"
              style={{ borderRadius: ".5rem" }}
            >
              <div className="row text-white align-items-center">
                <div className="col-md-1 offset-md-2">
                  <span className="text-left">
                    <i
                      className="fa fa-quote-left mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
                <div className="col-md-7">
                  <h3 className="text-white text-center ">
                    Genetics is about how information is stored and transmitted
                    between generations.
                  </h3>
                </div>
                <div className="col-md-1 text-right text-md-left">
                  <span className="">
                    <i
                      className="fa fa-quote-right mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section id="cta" className="cta bg-warning-light">
            <div className="container">
              <div className="row align-items-center my-2">
                <div className="col-md-10 offset-md-1 text-center">
                  <h2 className="text-center">Explore Your DNA Today.</h2>
                  <p>
                    Uncover your genetic predispositions. Manage your lifestyle
                    with personalised recommendations.
                  </p>
                </div>
              </div>
              <CTA />
            </div>
          </section>
          <section id="faq" className="py-5">
            <Faq />
          </section>
          <section className="py-5">
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-4 text-center d-flex align-items-center">
                  <img src="/img/logo.png" className="img-fluid" alt="logo" />
                </div>
                <div className="col-md-8">
                  <h2 className="mb-4">
                    We want to empower you to take charge of your health.
                  </h2>
                  <p className="mx-auto">
                    First, we want to help you start by fully understanding your
                    current health condition. Once you have a complete 360degree
                    view of your health and wellnes, it becomes easier for you
                    to identify the areas that requires improvement.
                  </p>
                  <p className="mx-auto">
                    Working towards a healthier lifestyle is not an easy
                    journey, especially with the vast information out there
                    catered towards all kinds of health/wellbeing.
                    <br /> Every individual is different, which is why our
                    reports will contain personalised information just for you.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <div className="row mt-1">
                <div className="col-12">
                  <h5 className="mb-4">Reference</h5>
                  <small>
                    <ol>
                      <li>
                        Expanded Carrier Screening and Frequently Asked
                        Questions - Grey Genetics. (2020). Retrieved from <br />
                        &emsp;https://www.greygenetics.com/expanded-carrier-screening-and-frequently-asked-questions/
                      </li>
                      <li>
                        Genetic Counseling | CDC. (2020). Retrieved from <br />
                        &emsp;https://www.cdc.gov/genomics/gtesting/genetic_counseling.html
                      </li>
                      <li>
                        Stenehjem, D., Au, T., Sainski, A., Bauer, H., Brown,
                        K., & Lancaster, J. et al. (2018). Impact of a genetic
                        counseling requirement prior to genetic testing. <br />
                        &emsp;BMC Health Services Research, 18(1). doi:
                        10.1186/s12913-018-2957-5
                      </li>
                      <li>
                        Ten questions to ask before doing a carrier screen |
                        Eugene Australia Blog. (2020). Retrieved from <br />
                        &emsp;https://eugenelabs.com/blog/10-questions-to-ask-before-doing-a-carrier-screen/
                      </li>
                      <li>
                        Testing, G., & I Have My Results, W. (2020). NSGC &gt;
                        Genetic Testing &gt; I Have My Results, What Happens
                        Next?. Retrieved from <br />
                        &emsp;http://www.aboutgeneticcounselors.com/Genetic-Testing/I-Have-My-Results-What-Happens-Next
                      </li>
                    </ol>
                  </small>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "DNA Explorer Carrier | Advanx Health",
    description:
      "Discover your carrier status to prevent your future family from potentially inheriting genetic disorders.  ",
    image: "https://www.advanxhealth.com/img/love-shape-hand.png",
    url: "https://www.advanxhealth.com/products/dna-explorer-carrier",
  },
  sections: [
    {
      img: "/img/illustrations/carrier-gene.svg",
      name: "We All Carry Gene Variants & It’s Normal",
      description:
        "However, carriers of recessive genetic disorders typically do not show any symptoms, so you won’t know if it’s there.",
    },
    {
      img: "/img/illustrations/carrier-inheritance.svg",
      name: "Some Gene Variants Affect Our Children",
      description:
        "When both parents are carriers of the same disorder, there is a 25% chance that their child would inherit the disorder.",
    },
    {
      img: "/img/illustrations/carrier-familyhistory.svg",
      name: "Family History Is Not Everything",
      description:
        "Most children with inherited genetic disorders have no known family history. Thus, it may be shocking for parents when their child is diagnosed with genetic disorders.",
    },
  ],
}
